import React, {useLayoutEffect, useRef} from 'react';
import {UseFormReturn} from 'react-hook-form';

import {Box} from '@mui/material';

import {
  VoiceBotEmbeddedTool,
  VoiceBotEmbeddedToolType,
  VoiceBotFunctionIntegrationMethod,
  VoiceBotTool,
  VoiceBotToolType,
} from '@wildix/wim-voicebots-client';

import {BotFormData} from '../../types';
import AddToolButton from './tools/AddToolButton';
import DelegateTool from './tools/DelegateTool';
import FunctionTool from './tools/FunctionTool';
import HangupTool from './tools/HangupTool';
import TransferTool from './tools/TransferTool';
import WaitTool from './tools/WaitTool';

interface BotLlmFunctionsFieldProps {
  form: UseFormReturn<BotFormData>;
}

export default function BotLlmFunctionsField(props: BotLlmFunctionsFieldProps) {
  const {form} = props;
  const {watch} = form;
  const tools = watch('endpoint.llm.tools');
  const embeddedTools = watch('endpoint.llm.embeddedTools');
  const ref = useRef<HTMLDivElement>(null);

  const [autoFocusIndex, setAutoFocusIndex] = React.useState<number | undefined>();

  const components: React.ReactNode[] = [];

  let num = 1;

  if (embeddedTools) {
    let toolsIndex = 0;

    for (const tool of embeddedTools) {
      if (tool.type === VoiceBotEmbeddedToolType.DELEGATE) {
        components.push(
          <DelegateTool
            form={form}
            path={`endpoint.llm.embeddedTools.${toolsIndex}`}
            index={toolsIndex}
            position={num++}
          />,
        );
      } else if (tool.type === VoiceBotEmbeddedToolType.HANGUP) {
        components.push(
          <HangupTool
            form={form}
            path={`endpoint.llm.embeddedTools.${toolsIndex}`}
            index={toolsIndex}
            position={num++}
          />,
        );
      } else if (tool.type === VoiceBotEmbeddedToolType.WAIT) {
        components.push(
          <WaitTool
            form={form}
            path={`endpoint.llm.embeddedTools.${toolsIndex}`}
            index={toolsIndex}
            position={num++}
          />,
        );
      } else if (tool.type === VoiceBotEmbeddedToolType.TRANSFER) {
        components.push(
          <TransferTool
            form={form}
            path={`endpoint.llm.embeddedTools.${toolsIndex}`}
            index={toolsIndex}
            position={num++}
          />,
        );
      }

      toolsIndex++;
    }
  }

  if (tools) {
    let toolsIndex = 0;

    for (const tool of tools) {
      components.push(
        <FunctionTool
          form={form}
          tool={tool}
          path={`endpoint.llm.tools.${toolsIndex}`}
          index={toolsIndex}
          position={num++}
        />,
      );
      toolsIndex++;
    }
  }

  const onAddToolClick = (type: string) => {
    const values = structuredClone(form.getValues());
    let embeddedToolToAdd: VoiceBotEmbeddedTool | undefined;
    let toolToAdd: VoiceBotTool | undefined;

    if (type === 'hangup') {
      embeddedToolToAdd = {
        type: VoiceBotEmbeddedToolType.HANGUP,
        name: 'hangup',
      };
    } else if (type === 'wait') {
      embeddedToolToAdd = {
        type: VoiceBotEmbeddedToolType.WAIT,
        name: 'wait',
      };
    } else if (type === 'transfer') {
      embeddedToolToAdd = {
        type: VoiceBotEmbeddedToolType.TRANSFER,
        name: 'transfer',
      };
    } else if (type === 'delegate') {
      embeddedToolToAdd = {
        type: VoiceBotEmbeddedToolType.DELEGATE,
        name: 'delegate',
      };
    } else if (type === 'webhook') {
      toolToAdd = {
        type: VoiceBotToolType.FUNCTION,
        function: {
          name: '',
          description: '',
          integration: {
            webhook: {
              url: '',
              method: VoiceBotFunctionIntegrationMethod.POST,
            },
          },
        },
      };
    }

    if (values.endpoint.llm) {
      if (embeddedToolToAdd) {
        values.endpoint.llm.embeddedTools = [...(values.endpoint.llm.embeddedTools || []), embeddedToolToAdd];

        setAutoFocusIndex(values.endpoint.llm.embeddedTools.length - 1);
      }

      if (toolToAdd) {
        values.endpoint.llm.tools = [...(values.endpoint.llm.tools || []), toolToAdd];

        setAutoFocusIndex((values.endpoint.llm.embeddedTools?.length || 0) + values.endpoint.llm.tools.length - 1);
      }

      form.reset(values);
    }
  };

  useLayoutEffect(() => {
    if (autoFocusIndex !== undefined) {
      if (ref.current) {
        const node = ref.current.querySelector(`#llm_tool_${autoFocusIndex}`);

        if (node) {
          setTimeout(() => {
            node.scrollIntoView({behavior: 'smooth'});
          }, 100);
        }

        setAutoFocusIndex(undefined);
      }
    }
  }, [autoFocusIndex]);

  return (
    <div ref={ref}>
      <AddToolButton tools={tools} embeddedTools={embeddedTools} onClick={onAddToolClick} />
      <div>
        {components.map((node, index) => (
          <Box id={`llm_tool_${index}`} mb={2} key={index}>
            {node}
          </Box>
        ))}
      </div>
    </div>
  );
}
