import React from 'react';
import {UseFormReturn} from 'react-hook-form';

import {IconButton} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import {VoiceBotFunctionIntegrationWebhookHeader} from '@wildix/wim-voicebots-client';

import {set} from 'lodash';

import RemoveIcon20 from '../../../../../base/icons/RemoveIcon20';
import {BotFormData} from '../../../types';
import VoiceBotTextFieldField from './base/VoiceBotTextField';
import Fieldset from './Fieldset';

interface FunctionIntegrationWebhookHeadersFieldProps {
  form: UseFormReturn<BotFormData>;
  path: string;
}

export default function FunctionIntegrationWebhookHeadersField(props: FunctionIntegrationWebhookHeadersFieldProps) {
  const {form, path} = props;
  const {watch, formState} = form;
  const {isSubmitting} = formState;
  const headers = watch(path as any) as VoiceBotFunctionIntegrationWebhookHeader[];

  const onAddHeaderClick = () => {
    const values = structuredClone(form.getValues());

    const newValue = [...headers, {key: '', value: ''}];
    const newValues = set(values, path, newValue);

    form.reset(newValues);
  };

  const onRemoveHeaderClick = (indexToDelete: number) => {
    const values = structuredClone(form.getValues());

    const newHeaders = headers.filter((item, index) => index !== indexToDelete);
    const newValue = newHeaders.length > 0 ? newHeaders : undefined;

    const newValues = set(values, path, newValue);

    form.reset(newValues);
  };

  if (!headers || headers.length === 0) {
    return null;
  }

  return (
    <Fieldset title="Headers">
      {headers.map((item, index) => (
        <Box key={index} display="flex" flexDirection="row" alignItems="flex-start" sx={{mt: index !== 0 ? 2 : 0}}>
          <Box sx={{mr: 1, flex: 1}}>
            <VoiceBotTextFieldField
              form={form}
              path={`${path}.${index}.key`}
              label="Key"
              rules={{required: true}}
              disabled={isSubmitting}
            />
          </Box>
          <Box sx={{ml: 1, flex: 1}}>
            <VoiceBotTextFieldField
              form={form}
              path={`${path}.${index}.value`}
              label="Value"
              disabled={isSubmitting}
              rules={{required: true}}
            />
          </Box>
          <IconButton
            sx={{ml: 1, mt: 0.5, width: '32px', height: '32px', padding: '6px'}}
            onClick={() => onRemoveHeaderClick(index)}
            disabled={isSubmitting}>
            <RemoveIcon20 style={{width: '20px', height: '20px', fill: 'none'}} />
          </IconButton>
        </Box>
      ))}
      <Button variant="text" sx={{fontSize: '10px', mt: 0.5}} size="small" onClick={onAddHeaderClick}>
        Add header
      </Button>
    </Fieldset>
  );
}
